/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ProductSuiteUltraFastSaving.css";

export default function ProductSuite() {
  const imgCDNURL = process.env.REACT_APP_CDN + "realtor-cobranding/";

  return (
    <>
      <div className="lapisection">
        <img
          src={imgCDNURL + "leftblurshdow.webp"}
          className="leftshdoe img-fluild"
          alt="leftblurshdow"
        ></img>
        <img
          src={imgCDNURL + "dotmatrix.webp"}
          className="dotmatrixright img-fluild"
          alt="dotmatrixright"
        ></img>
        <Container>
          <Row className="">
            <Col sm={12} md={12} xl={4} lg={4}>
              <img
                src={imgCDNURL + "governmentloanpic.webp"}
                className="lapiimag img-fluild"
                alt="governmentloanpic"
              ></img>
            </Col>
            <Col sm={12} md={12} xl={8} lg={8}>
              <div className="lapidessection pb-2">
                <h3 className="lapisectionhead ">
                  Experience the Future of Mortgage Applications with Ai:
                </h3>

                <ul>
                  <li>Faster, easier, and transparent mortgage process</li>
                  <li>Get TRU Approval<sup>&#174;</sup> on demand</li>
                  <li>Personalized mortgage recommendations based on your needs</li>
                  <li>Compare loan options, estimate monthly payments, and calculate closing costs</li>
                  <li>Accessible 24/7 from any device (computer, tablet, smartphone)</li>
                  <li>Ideal for first-time homebuyers and seasoned real estate investors</li>
                  <li>User-Friendly interface for all experience levels</li>
                </ul>

                <p className="getConnectedText ps-2">
                  Get connected with your Loan Officer and Realtor<sup>&#174;</sup> on chat in real-time.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}