import React from "react";
import { VideoMain, VideoBox, Close } from "../Video/styles";
import x from "../Img/xicon.webp";

const VideoModal = ({ onClose, videoId }) => {
  if (!videoId) {
    return null; // Render nothing if videoId is not provided
  }

  return (
    <VideoMain
      className="animate__animated animate__fadeIn"
      videoToggle={true}
      onClick={() => onClose()}
      contentLabel="Video Modal"
    >
      <VideoBox className="animate__animated animate__bounceInUp">
        <Close src={x} />
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
          title="YouTube Video"
          frameBorder="0"
          allowFullScreen
          autoplay
          muted
          className="videoframe"
        ></iframe>
      </VideoBox>
    </VideoMain>
  );
};

export default VideoModal;
