import styled from "styled-components";

const StyledRealtorCarousel = styled.section`
  @media only screen and (min-width: 1600px) {
    & .xxl {
      max-width: 1800px;
    }
  }

  & {
    margin-bottom: 6rem;
  }
  & .carouselItem {
    transform: translateX(-0.75rem);
    padding-block: 1rem;
    margin-inline: auto;
    margin-block: 0;
    display: flex;
    justify-content: center;
  }
  & .carousel-row {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  & .realtor-box {
    width: calc(300px + 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 0rem 0.5rem;
    background: #ffffff;
    box-shadow: 0px 4.15148px 25.9467px rgba(0, 0, 0, 0.25);
    border-radius: 10.3787px;
  }

  & .react-multi-carousel-dot-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    padding-inline: 1rem;
    transform: translateY(2rem);
  }
  & .image-box {
    width: 100%;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top - 3rem;
    background-color: #d9d9d9;
    border-radius: 10.3787px;
  }
  & .carousel-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 57px;
    text-align: center;
    color: #093f89;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  & .carousel-subline {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    text-align: center;
    color: #234b7a; /* Improved contrast */
    margin-bottom: 2rem;
  }
  & .text-box {
    margin-block: 1rem;
    text-align: center;
  }
  & .text-box p {
    margin-bottom: 0;
  }
  & .realtor-name {
    text-decoration: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #0077b6; /* Improved contrast */
  }
  & .nmls {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #1d2d70; /* Improved contrast */
  }

  & .react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: black;
  }

  & .react-multi-carousel-dot button {
    border: none;
    background: #d9d9d9;
  }
  @media only screen and (max-width: 575px) {
    & .react-multi-carousel-dot-list {
      transform: translateY(5rem);
    }
    & {
      margin-bottom: 8rem;
    }
  }
  @media only screen and (max-width: 450px) {
    & .carousel-title {
      font-size: 32px;
      line-height: 45px;
    }
    & .carousel-subline {
      font-size: 22px;
      line-height: 35px;
    }
    & .react-multi-carousel-dot-list {
      transform: translateY(6rem);
    }
    & {
      margin-bottom: 9rem;
    }
  }

  @media only screen and (max-width: 375px) {
    & .react-multi-carousel-dot-list {
      transform: translateY(6rem);
    }

    & {
      margin-bottom: 9rem;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    & .realtor-box {
      width: calc(225px + 1rem);
    }
    & .image-box {
      height: 250px;
    }
    & .realtor-name {
      font-size: 22px;
      line-height: 30px;
    }
    & .nmls {
      font-size: 16px;
      line-height: 25px;
    }
    & .react-multi-carousel-dot-list {
      transform: translateY(4rem);
    }
    & {
      margin-bottom: 8rem;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    & .carousel-title {
      font-size: 36px;
      line-height: 45px;
    }
    & .carousel-subline {
      font-size: 24px;
      line-height: 35px;
    }
    & .react-multi-carousel-dot-list {
      transform: translateY(3rem);
    }
    & {
      margin-bottom: 8rem;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    & .realtor-box {
      width: calc(275px + 1rem);
    }
    & .image-box {
      height: 275px;
    }
    & .realtor-name {
      font-size: 22px;
      line-height: 30px;
    }
    & .nmls {
      font-size: 16px;
      line-height: 25px;
    }
    & .carousel-title {
      font-size: 33px;
      line-height: 45px;
    }
    & .carousel-subline {
      font-size: 20px;
      line-height: 35px;
    }
    & .react-multi-carousel-dot-list {
      transform: translateY(2.5rem);
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    & .realtor-box {
      width: calc(250px + 1rem);
    }
    & .image-box {
      height: 275px;
    }
    & .realtor-name {
      font-size: 22px;
      line-height: 30px;
    }
    & .nmls {
      font-size: 16px;
      line-height: 25px;
    }
    & .react-multi-carousel-dot-list {
      transform: translateY(1rem);
    }
  }
  @media only screen and (min-width: 1400px) {
    & .carousel-subline {
      padding-inline: 6rem;
    }
  }
`;

export default StyledRealtorCarousel;
