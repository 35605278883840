/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useEffect, useState } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import "./index.css";

export default function RigVedQuotes({ showRigVedQuotes, closeRigved }) {
  const [rigVedQuotes, setRigVedQuotes] = useState([]);

  useEffect(() => {
    /**
     * @author Pratik Kajrolkar
     * @date 07/20/2023
     * The below function is used to get rigved quotes from json file
     */
    const fetchRigVedQuotes = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_RIGVED_QUOTES_JSON)
          .then(async (response) => {
            const responseData = response.data;
            setRigVedQuotes(responseData.quotes);
          })
          .catch((error) => {
            console.log("Unable to load rigveda quotes: ", error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    fetchRigVedQuotes();
  }, []);

  return (
    <>
      {showRigVedQuotes ? (
        <div id="rigvedQuotesContainer">
          <Carousel controls={false} interval={3000} indicators={false} onClick={(e) => {
      e.stopPropagation(); // Prevent click from propagating to the document
      closeRigved();
    }}>
            {rigVedQuotes.map((item, i) => {
              return (
                <Carousel.Item key={i}>
                  <Container>
                    <Row>
                      <Col className="mt-2 mb-2">
                        <p className="text-center">
                          <sup>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="12"
                              viewBox="0 0 21 16"
                              fill="none"
                            >
                              <path
                                d="M5.26308 5.95956C4.67643 5.95956 4.11538 6.05996 3.5937 6.24698C3.65276 6.05406 3.72166 5.8631 3.79646 5.67017C4.04648 5.08943 4.34571 4.53429 4.71384 4.0323C5.456 3.04012 6.45212 2.27629 7.73762 2.17589H7.76518C7.99551 2.16015 8.20221 2.00069 8.27111 1.77036C8.36757 1.46129 8.1904 1.14041 7.88723 1.04394C7.05451 0.797869 6.11548 0.856929 5.27883 1.14435C4.43626 1.43767 3.68819 1.92391 3.06414 2.5145C1.8003 3.6996 0.983324 5.24496 0.552198 6.86709C0.16832 8.30221 0.071858 9.82788 0.333683 11.326C0.339589 11.3319 0.333683 11.3358 0.333683 11.3358C0.343526 11.3949 0.355338 11.4539 0.371086 11.5169C0.439988 12.0721 0.601414 12.5938 0.839615 13.0741C1.64478 14.7002 3.32006 15.8223 5.26111 15.8223C5.30442 15.8223 5.35166 15.8223 5.39497 15.8164C8.05653 15.7475 10.1964 13.5702 10.1964 10.887C10.1964 8.20377 7.98763 5.95169 5.26111 5.95169L5.26308 5.95956Z"
                                fill="#FF541C"
                              />
                              <path
                                d="M15.931 5.95931C15.3444 5.95931 14.7833 6.05972 14.2617 6.24674C14.3207 6.05381 14.3896 5.86285 14.4644 5.66993C14.7144 5.08919 15.0137 4.53405 15.3818 4.03205C16.124 3.03987 17.1201 2.27605 18.4056 2.17565H18.4331C18.6635 2.1599 18.8702 2.00044 18.9391 1.77012C19.0355 1.46105 18.8584 1.14016 18.5552 1.0437C17.7225 0.797624 16.7835 0.856685 15.9468 1.1441C15.1042 1.43742 14.3562 1.92367 13.7321 2.51425C12.4683 3.69935 11.6513 5.24471 11.2202 6.86685C10.8363 8.30196 10.7398 9.82763 11.0017 11.3257C11.0076 11.3316 11.0017 11.3356 11.0017 11.3356C11.0115 11.3946 11.0233 11.4537 11.0391 11.5167C11.108 12.0718 11.2694 12.5935 11.5076 13.0739C12.3127 14.6999 13.988 15.822 15.9291 15.822C15.9724 15.822 16.0196 15.822 16.0629 15.8161C18.7245 15.7472 20.8644 13.57 20.8644 10.8867C20.8644 8.20353 18.6556 5.95145 15.9291 5.95145L15.931 5.95931Z"
                                fill="#FF541C"
                              />
                            </svg>
                          </sup>{" "}
                          {item.quote}{" "}
                          <sup>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="12"
                              viewBox="0 0 23 17"
                              fill="none"
                            >
                              <path
                                d="M16.6769 10.6339C17.3004 10.6339 17.8967 10.5272 18.4511 10.3284C18.3884 10.5335 18.3151 10.7364 18.2356 10.9415C17.9699 11.5587 17.6519 12.1487 17.2606 12.6822C16.4719 13.7367 15.4132 14.5485 14.047 14.6552L14.0177 14.6552C13.7729 14.6719 13.5532 14.8414 13.48 15.0862C13.3774 15.4147 13.5657 15.7557 13.888 15.8582C14.773 16.1198 15.771 16.057 16.6602 15.7515C17.5556 15.4398 18.3507 14.923 19.0139 14.2953C20.3571 13.0358 21.2254 11.3934 21.6836 9.66939C22.0916 8.14415 22.1941 6.52267 21.9159 4.93048C21.9096 4.9242 21.9159 4.92002 21.9159 4.92002C21.9054 4.85725 21.8929 4.79448 21.8761 4.72753C21.8029 4.13752 21.6313 3.58307 21.3782 3.07257C20.5224 1.34438 18.7419 0.151807 16.679 0.151807C16.633 0.151807 16.5828 0.151807 16.5367 0.158084C13.708 0.231312 11.4338 2.54533 11.4338 5.39705C11.4338 8.24877 13.7812 10.6423 16.679 10.6423L16.6769 10.6339Z"
                                fill="#FF541C"
                              />
                              <path
                                d="M5.33901 10.6342C5.9625 10.6342 6.55879 10.5275 7.11323 10.3287C7.05046 10.5337 6.97724 10.7367 6.89773 10.9417C6.63202 11.5589 6.314 12.1489 5.92275 12.6825C5.13397 13.7369 4.0753 14.5487 2.70907 14.6554L2.67978 14.6554C2.43499 14.6722 2.2153 14.8416 2.14207 15.0864C2.03955 15.4149 2.22786 15.756 2.55006 15.8585C3.43508 16.12 4.43307 16.0572 5.32228 15.7518C6.21775 15.44 7.0128 14.9232 7.67604 14.2956C9.01926 13.036 9.88754 11.3936 10.3457 9.66964C10.7537 8.14439 10.8562 6.52291 10.578 4.93072C10.5717 4.92444 10.578 4.92026 10.578 4.92026C10.5675 4.85749 10.555 4.79472 10.5382 4.72777C10.465 4.13776 10.2934 3.58332 10.0403 3.07281C9.18455 1.34463 7.40405 0.152052 5.34111 0.152051C5.29508 0.152051 5.24486 0.152051 5.19883 0.158329C2.37013 0.231556 0.0958683 2.54557 0.0958681 5.39729C0.0958678 8.24901 2.44336 10.6425 5.34111 10.6425L5.33901 10.6342Z"
                                fill="#FF541C"
                              />
                            </svg>
                          </sup>
                        </p>
                        <p className="text-center mb-0">
                          {" - "}
                          {item.quoteBy}
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      ) : (
        ""
      )}
    </>
  );
}