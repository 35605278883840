import React, { useEffect, useState, useRef, useCallback } from "react";
import StyledRealtorCarousel from "./styles";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: { breakpoint: { max: 3000, min: 1600 }, items: 5 },
  desktop: { breakpoint: { max: 1599, min: 1200 }, items: 4 },
  largeTablet: { breakpoint: { max: 1199, min: 992 }, items: 3 },
  tablet: { breakpoint: { max: 991, min: 576 }, items: 2 },
  mobile: { breakpoint: { max: 575, min: 1 }, items: 1 },
};

export default function RealtorCarousel() {
  const [realtors, setRealtors] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getRealtorData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_REALTOR_MASTER_LIST);
        if (response?.data) {
          const dataCopy = [...response.data].slice(1); // Remove copyright object
          setRealtors(dataCopy);
        }
      } catch (err) {
        setError("Failed to load realtors. Please try again later.");
        console.error(err);
      }
    };
    getRealtorData();
  }, []);

  return (
    <StyledRealtorCarousel>
      <Container>
        <Row>
          <Col>
            <h3 className="carousel-title">Meet the Agents Transforming Real Estate with AngelAi</h3>
            <p className="carousel-subline">
              Connect with top agents powered by AngelAi. Get personalized service and expert guidance
              to help you find, buy, or sell your dream home effortlessly!
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="xxl">
        <Row className="carousel-row">
          {error ? (
            <p className="error-message">{error}</p>
          ) : realtors ? (
            <Carousel
              responsive={responsive}
              arrows={false}
              showDots={true}
              infinite={true}
              containerClass="carousel-container"
              renderDotsOutside={true}
              autoPlay
              autoPlaySpeed={6000}
              partialVisible={false}
              itemClass="carouselItem"
            >
              {realtors.map((obj) => (
                <CarouselItem key={obj.license} obj={obj} />
              ))}
            </Carousel>
          ) : (
            <Spinner />
          )}
        </Row>
      </Container>
    </StyledRealtorCarousel>
  );
}

const CarouselItem = ({ obj }) => {
  const [isVisible, setIsVisible] = useState(false);
  const itemRef = useRef(null);
  const { name, linkName, profileImage, license } = obj;
  const realtorLink = `/${linkName}`;

  const observerCallback = useCallback(([entry], observer) => {
    if (entry.isIntersecting) {
      setIsVisible(true);
      observer.unobserve(entry.target); // Stop observing once visible
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      rootMargin: "50px",
      threshold: 0.1,
    });

    const currentItemRef = itemRef.current; // Store the current ref

    if (currentItemRef) observer.observe(currentItemRef);

    return () => {
      if (currentItemRef) observer.unobserve(currentItemRef);
    };
  }, [observerCallback]);

  return (
    <Link
      ref={itemRef}
      to={isVisible ? realtorLink : "#"}
      className="mb-3 text-decoration-none carousel-link"
      tabIndex={isVisible ? "0" : "-1"}
    >
      <div className="realtor-box">
        <div
          loading="lazy"
          fetchpriority="low"
          title={name}
          style={{
            backgroundImage: isVisible ? `url(${profileImage})` : "none",
          }}
          className="image-box"
        ></div>
        <div className="text-box">
          <p className="realtor-name">{name}</p>
          <p className="nmls">License #{license}</p>
        </div>
      </div>
    </Link>
  );
};

