import React, { lazy, Suspense } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {
  capitalizeFirstLetter,
  formatPhoneNumber,
  addInternationalCode,
} from "../Utils";
import parser from "html-react-parser";
import "./LoanOfficer.css";

export default function LoanOfficer({ loanOfficerActive, loanOfficerData, angelAiUserInfo, realtorData, aiBrandingData }) {
  const Borrowerlead = lazy(() =>
    import("../BorrowerLeadForm/BorrowerLeadForm.js")
  );
  const imgCDNURL = process.env.REACT_APP_CDN + "realtor-cobranding/";
  const wave = imgCDNURL + "wave.webp";
  const loanOfficerName = loanOfficerData ? capitalizeFirstLetter(loanOfficerData.name) : "";
  // console.log("loanOfficerName", loanOfficerData);
  const phoneNumber = loanOfficerData ? (loanOfficerData.mobilePhoneNumber ? loanOfficerData.mobilePhoneNumber : loanOfficerData.workPhoneNumber) : "";
  const phoneNumberFormatted = loanOfficerData ? formatPhoneNumber(phoneNumber) : "";
  const phoneNumberWithInternationalCode = loanOfficerData ? addInternationalCode(phoneNumber, "+1") : "";
  const phoneNumberInTitle = loanOfficerData ? "Contact me at " + phoneNumberFormatted : "";
  let angelAiChatURL;
  if (loanOfficerActive) {
    angelAiChatURL = loanOfficerData && loanOfficerData.angelAiChatURL ? loanOfficerData.angelAiChatURL : aiBrandingData.current.webAppURL;
  } else {
    angelAiChatURL = realtorData && realtorData.angelAiChatURL ? realtorData.angelAiChatURL : aiBrandingData.current.webAppURL;
  }

  return (
    <>
      <div className="loanOfficerContainer">
        <img
          src={imgCDNURL + "mobileleftshadow.webp"}
          className="leftshadowmobile img-fluid"
          alt="mobileleftshadow"
        />
        <img
          src={imgCDNURL + "dotmatrix.webp"}
          className="doxmatruxmbie img-fluid"
          alt="dotmatrix"
        />
        <img src={wave} className="wavepart img-fluid" alt="wave" />
        <img
          src={imgCDNURL + "right-shadow-icon.webp"}
          className="rightshdowtst img-fluid"
          alt="right-shadow-icon"
        />
      
        <Container className="mt-sm-5 mb-5">
          <Row>
            {loanOfficerActive && loanOfficerData ? (
              <Col sm={12} md={12} lg={7} xl={8} className="borderofjoa">
                <Row className="pt-4">
                  <Col sm={12} md={12} lg={6} xl={6}>
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-12 jaquienborder">
                          <img
                            src={loanOfficerData.profileImage}
                            className="profileImg img-fluid"
                            alt={loanOfficerName}
                          />
                        </div>
                        <div className="col-sm-12 text-center">
                          <Button href={angelAiChatURL} 
                            target='_blank' rel="noreferrer noopener" 
                            className="text-decoration-none askAngelAiNowBtn">
                            Ask AngelAi Now!
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={12} xl={6} lg={6}>
                    <p className="namejoa">
                      {capitalizeFirstLetter(loanOfficerData.name)}
                    </p>
                    <p className="joainfo">
                      {loanOfficerData.nmls ? (
                        <>
                          NMLS # {loanOfficerData.nmls}
                          <br></br>
                        </>
                      ) : (
                        ""
                      )}
                      {loanOfficerData.designation ? (
                        <>
                          {loanOfficerData.designation}
                          <br></br>
                        </>
                      ) : (
                        ""
                      )}
                      {phoneNumber ? (
                        <>
                          <a
                            href={"tel:" + phoneNumberWithInternationalCode}
                            title={phoneNumberInTitle}
                            aria-label={phoneNumberInTitle}
                          >
                            {phoneNumberFormatted}
                          </a>
                          <br></br>
                        </>
                      ) : (
                        ""
                      )}
                      {loanOfficerData.emailAddress ? (
                        <>
                          <a
                            href={"mailto:" + loanOfficerData.emailAddress}
                            title={"Email me at " + loanOfficerData.emailAddress}
                            aria-label={"Email me at " + loanOfficerData.emailAddress}
                          >
                            {loanOfficerData.emailAddress}
                          </a>
                          <br></br>
                        </>
                      ) : (
                        ""
                      )}
                      {loanOfficerData.userWebsite ? (
                        <>
                          <a
                            href={loanOfficerData.userWebsite}
                            title={"Visit my page at " + loanOfficerData.userWebsite + " for more details"}
                            aria-label={"Visit my page at " + loanOfficerData.userWebsite + " for more details"}
                            target="_blank"
                            rel='noreferrer'
                          >
                            {loanOfficerData.userWebsite.replace("https://", "")}
                          </a>
                          <br></br>
                        </>
                      ) : (
                        ""
                      )}
                      {loanOfficerData.companyName && loanOfficerData.branchNMLS ? (
                        <>
                          {capitalizeFirstLetter(loanOfficerData.companyName)} NMLS {loanOfficerData.branchNMLS}
                          <br></br>
                        </>
                      ) : (
                        ""
                      )}

                      {loanOfficerData.streetAddress ? (
                        <>
                          {loanOfficerData.streetAddress}
                          <br></br>
                          {loanOfficerData.cityStateZip}
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </Col>
                </Row>
                {loanOfficerData.bio && (
                  <p className="expertisetext">
                    {parser(loanOfficerData.bio)}
                  </p>
                )}
              </Col>
            ) : (
              <Col sm={12} md={12} lg={7} xl={8} className="askAngelAiContainer">
                  <p className="fairlendingNow mt-3 mb-0">
                  Get a fast, low cost, fair, and 100% Trusted loan approval and closing by Sun West Mortgage, <span>NMLS 3277</span>
                  </p>
                  <p className="aiYouCanBankOn mb-0">
                    Ai You Can Bank On
                  </p>
                  <div>
                    <div>
                      <a href={angelAiChatURL} target='_blank' rel="noreferrer noopener">
                        <img src={process.env.REACT_APP_ANGELAI_IMAGES + 'angelai-operator-loop.webp'}
                            className='angelai-operator img-fluid' alt='AngelAi Operator' fetchpriority="high" />
                      </a>
                    </div>
                    <div className="ask-button mb-3">
                      <a href={angelAiChatURL} target='_blank' rel="noreferrer noopener" className="text-decoration-none">
                        <Button className='askangelai-button'>
                          <p>Ask me anything about home finance!</p>
                        </Button>
                    </a>
                    </div>
                  </div>
              </Col>
            )}
            <Col
              sm={12}
              md={12}
              lg={5}
              xl={4}
              id="contactusform"
            >
              <Suspense fallback={<div />}>
                <Borrowerlead loanOfficerData={loanOfficerData} realtorData={realtorData} loanOfficerUid={loanOfficerActive && loanOfficerData ? loanOfficerData.ssid : (angelAiUserInfo ? angelAiUserInfo.angelAIUserInfo.userID : 'CSTC')} />
              </Suspense>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
