import React, { useCallback, useState } from "react";
import { BorrowerLeadGenerationTool } from "borrower-lead-generation-tool";
import "./BorrowerLeadForm.css";

const BorrowerLeadForm = ({ loanOfficerData, realtorData, ...props }) => {
  // stores current formRef
  const [node, setNode] = useState(null);
  // tracks changes to formRef and stores current with setNode
  const formRef = useCallback((node) => {
    setNode(node);
  }, []);

  // console.log("loanOfficerData", loanOfficerData);
  // console.log("realtorData", realtorData);

  // console.log("laonofficer email", loanOfficerData.emailAddress);
  //   console.log("realtoer email", realtorData.emailAddress);

  let emailAddresses;

  if (realtorData && loanOfficerData) {
    emailAddresses = [realtorData.emailAddress, loanOfficerData.emailAddress];
  } else if (realtorData) {
    emailAddresses = [realtorData.emailAddress];
  } else if (loanOfficerData) {
    emailAddresses = [loanOfficerData.emailAddress];
  } else {
    emailAddresses = null;
  }

  let filteredEmailAddresses, angelAiNotifyObj;

  if (emailAddresses) {
    filteredEmailAddresses = emailAddresses.filter(email => email);
  }

  if (filteredEmailAddresses) {
    angelAiNotifyObj = {
      sender: { email: "angelai@celligence.com" },
      recipients: filteredEmailAddresses.map((email) => ({ email }))
    };
  }

  return (
    <>
    <div id="applynow"></div>
    <div id="contact-wrapper">
      {node && (
        // dynamically renders the custom form header
        <div className="formHeader">
          {props.header ? props.header : ""}
          <h2 className="Connect">Ready to Get Started?</h2>
        </div>
      )}

      <div className="formBody">
        <BorrowerLeadGenerationTool
          ref={formRef}
          mainBtnName="Tell Me More"
          loanOfficerUid={props.loanOfficerUid}
          leadSource="SELF"
          showPropertyStateField={false}
          showReactPlayerVideoWindow={true}
          showSWMCConsent={false}
          angelAiNotifyObj={angelAiNotifyObj}
          domainNameConsent="angelai.com"
          language="en"
        />
      </div>
    </div>
    </>
  );
};
export default BorrowerLeadForm;
