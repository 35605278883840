import React, { useEffect } from "react";

const AnalyticsAndWidgets = () => {
  useEffect(() => {
    
    // Load Angel Ai Widget
    const loadAngelAiWidget = () => {
      (function (w, d, s, a, g, e, o) {
        w[g] =
          w[g] ||
          function () {
            (w[g].q = w[g].q || []).push(arguments);
          };
        const l = d.createElement("div");
        l.id = "mini-Morgan-chat-widget";
        d.getElementsByTagName("body")[0].appendChild(l);
        e = d.createElement(s);
        o = d.getElementsByTagName(s)[0];
        e.async = true;
        e.src = a;
        e.setAttribute("type", "text/javascript");
        o.parentNode.insertBefore(e, o);
      })(
        window,
        document,
        "script",
        "https://d2w24n4g34usfg.cloudfront.net/morgan/widget/index.js",
        "mmc"
      );

      if (window.mmc) {
        window.mmc(350, 430, "left", 10, 10, 999);
      }
    };

    // Load all scripts on component mount

    loadAngelAiWidget();

  }, []);

  return <React.Fragment />;
};

export default AnalyticsAndWidgets;
