import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "./TruApprovalAngelAi.css";
import {
  capitalizeFirstLetter
} from "../Utils";
import VideoModal from "../Video/VideoModal";
import { StyledModal } from "../Video/styles";

export default function TruApproval({ realtorData, loanOfficerActive, loanOfficerData, angelAiUserInfo }) {
  const [selectedVideoId, setSelectedVideoId] = useState('');
  const [play, setPlay] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [termsData, setTermsData] = useState({
    title: "",
    conditions: [],
    limitation: { title: "", text: "" }
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Remove trailing slashes from angelAiChatURL and handle null/undefined cases
let angelAiChatURL = "https://www.swmc.com/angelai"; // Default fallback URL

if (loanOfficerActive && loanOfficerData) {
  angelAiChatURL = loanOfficerData.angelAiChatURL ? 
    loanOfficerData.angelAiChatURL.replace(/\/$/, '') : 
    "https://www.swmc.com/angelai";
} else if (realtorData) {
  angelAiChatURL = realtorData.angelAiChatURL ? 
    realtorData.angelAiChatURL.replace(/\/$/, '') : 
    "https://www.swmc.com/angelai";
}

  useEffect(() => {
    // This function will be called if the terms modal is opened but data hasn't been fetched yet
    const fetchTermsData = async () => {
      if (showTermsModal && termsData.conditions.length === 0 && !isLoading) {
        setIsLoading(true);
        setError(null);
        try {
          // Replace this URL with the actual URL where you'll host the JSON file on your CDN
          const response = await axios.get('https://staging-cdn.swmc.com/common-components/jsons/truTermsConditions.json');
          setTermsData(response.data);
        } catch (err) {
          console.error("Error fetching terms and conditions:", err);
          setError("Failed to load terms and conditions. Please try again later.");
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchTermsData();
  }, [showTermsModal, termsData.conditions.length, isLoading]);

  const videoToggle = (play, videoId) => {
    setPlay(!play);
    setSelectedVideoId(videoId);
  };

  const toggleTermsModal = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    setShowTermsModal(!showTermsModal);
  };

  const imgCDNURL = process.env.REACT_APP_CDN + "realtor-cobranding/";
  // const truapprovalVideoLink = "https://www.youtube.com/watch?v=jBfDIGn_Jak";
  // const realtorName = realtorData ? capitalizeFirstLetter(realtorData.name) : "";
  const angelAiName = angelAiUserInfo ? capitalizeFirstLetter(angelAiUserInfo.angelAIUserInfo.fullName) : "";
  const loanOfficerName = loanOfficerData ? capitalizeFirstLetter(loanOfficerData.name) : "";
  const loanOfficerFirstNameLastName = loanOfficerData && loanOfficerData.emailAddress ? ((loanOfficerData.emailAddress).split('@')[0].replace(/[^a-zA-Z]/g, '')).toLowerCase() : "";
  const loSSID = loanOfficerData ? loanOfficerData.ssid : "";
  let loName;
  let truApprovalLink = (angelAiChatURL || "https://www.swmc.com/angelai").replace(/\/$/, '') + "/Loan%20Application?opts=w";

  // if (loanOfficerActive) {
  //   truApprovalLink = `${truApprovalLink}/${loanOfficerFirstNameLastName}`
  // }
  let truTermsLink = "https://www.swmc.com/tru-terms";

  if (loanOfficerActive) {
    loName = loanOfficerName;
    //if loan officer is present then it will redirect to loan officer pagesa
    truApprovalLink = `${truApprovalLink}/${loanOfficerFirstNameLastName}`
    truTermsLink = `${truTermsLink}?rlo=${loSSID}`
  } else {
    loName = angelAiName;
  }
  const SunWestName = loanOfficerData ? "Sun West" : "Sun West Mortgage Company, Inc. NMLS 3277"
  
  return (
    <>
      <div id="gettrueapproveScroll"></div>
      <div>
        <Container>
          <Row className="mt-5 mb-5" id="gettrueapprove">
            <Col sm={12} md={12} xl={4} lg={4}>
             
                <img
                  src={imgCDNURL + "tru-approval-img-play-btn.webp"}
                  className="girlwithboyhouse img-fluid"
                  aria-label="TRU Approval: Your First Step to Home Ownership"
                  title="TRU Approval: Your First Step to Home Ownership"
                  onClick={() => videoToggle(play, '7shKprV80dQ')}
                />
         
            </Col>
            <Col sm={12} md={12} xl={8} lg={8} className="">
              <img
                src={imgCDNURL + "dotmatrix.webp"}
                className="dotmatrix img-fluid"
                alt="dotmatrix"
              />
              <h3 className="ps-0 trueapprovehead">
                Get a{" "}
                <a
                  href={truApprovalLink}
                  target="_blank"
                  rel="noreferrer noopener"
                  title={truApprovalLink}
                  className="trueapprovehead p-0"
                >
                  TRU Approval
                </a>
                <sup>&#174;</sup> Today!
              </h3>
             
              <p className="Thoroughlytext">
                Know how much you can afford and stand out from other buyers
                with a loan approval equal in strength to a cash offer.
              </p>
              <p className="Thoroughlytext">
                A TRU Approved offer has NO FINANCIAL CONTINGENCIES and has a{" "}
              
                  {SunWestName}{" "}            Conditional Warranty*.
              </p>
             
              <p onClick={() => videoToggle(play, '7shKprV80dQ')} className="truapprovalVideoLink">
               
                  <img 
                    src={imgCDNURL + "polygon-2.webp"}
                    className="img-fluid pb-2"
                    alt="polygon-2"
                    onClick={() => videoToggle(play, '7shKprV80dQ')}
                  />
                  <span className="ps-3">          
               
                  TRU Approval<sup>&#174;</sup>: Your First Step to Homeownership video</span>
               
              </p>
              <p className="Thoroughlytextsmall">
                *
                <a
                  href="#"
                  onClick={toggleTermsModal}
                  aria-label="View Terms and Conditions"
                  className="swmc-tru-terms"
                >
               Click here
                </a>{" "}
                for TRU Approval<sup>&#174;</sup> terms and conditions.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      
      {/* Video Modal */}
      <StyledModal show={play}>
        <VideoModal onClose={() => videoToggle(play, "")} videoId={selectedVideoId} />
      </StyledModal>
      
      {/* Terms and Conditions Modal */}
      <Modal
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
        size="lg"
        aria-labelledby="terms-modal-title"
        centered
        className="terms-conditions-modal"
        dialogClassName="modal-fixed-height"
        enforceFocus={false}
        backdrop="static"
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="terms-modal-title">
           
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="terms-modal-body">
  {isLoading ? (
    <p>Loading terms and conditions...</p>
  ) : error ? (
    <p className="text-danger">{error}</p>
  ) : (
    <div className="terms-content">
      <h6 className="mb-3"><strong>{termsData.title}</strong></h6>
      
      <ul className="main-bullet-list">
        {termsData.conditions.map((condition, index) => (
          <li key={index} className="main-bullet-item">
            <span dangerouslySetInnerHTML={{ __html: condition.main.replace(/®/g, '<sup>®</sup>').replace(/\[(\d+)\]/g, '<sup>[$1]</sup>') }}></span>
            
            {condition.subItems && condition.subItems.length > 0 && (
              <ul className="sub-bullet-list">
                {condition.subItems.map((subItem, subIndex) => (
                  <li key={subIndex} className="sub-bullet-item">
                    <span dangerouslySetInnerHTML={{ __html: subItem.replace(/®/g, '<sup>®</sup>').replace(/\[(\d+)\]/g, '<sup>[$1]</sup>') }}></span>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      
      {termsData.limitation && (
        <div className="limitation-section">
          <h5><strong>{termsData.limitation.title}</strong></h5>
          <p>{termsData.limitation.text}</p>
        </div>
      )}
      
      {/* Footnotes section */}
      {termsData.footnotes && termsData.footnotes.length > 0 && (
        <div className="footnotes-section">
          {termsData.footnotes.map((footnote, index) => (
            <p key={index}>
              [{footnote.id}] {footnote.text}{' '}
              {footnote.link && (
                <a 
                  href={footnote.link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {footnote.link}
                </a>
              )}
            </p>
          ))}
        </div>
      )}
    </div>
  )}
</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTermsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}