/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React, {lazy, Suspense,Fragment} from "react";
import { AngelaiFooter } from "footer-component";
import "./Footer.css";
// const AngelaiFooter = lazy(()=>import('footer-component'))
 
const CustomFooter = () => {
 
  return (
      <>
      <Suspense fallback={<Fragment/>}>
      <AngelaiFooter type="angelai" showServicesDisclaimer = {false} />
      </Suspense>
   
   </>
  );
};

export default CustomFooter;