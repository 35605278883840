import React, { useEffect, useRef, useState, lazy, Suspense, Fragment } from "react";
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import UserWayAccessibilityMenu from "../UserWayAccessibilityMenu/index.js";
import { capitalizeFirstLetter, isMobileOrTablet } from "../Utils";
import "./index.css";
import HTMLReactParser from "html-react-parser";
import axios from "axios";

const RigVedQuotes = lazy(() => import("../RigvedQuotes/index.js"));
const Widgets = lazy(() => import('../Scripts/Widgets'));

const HeaderNavbar = ({
  handleShowOtherComponents,
  loanOfficerActive,
  loanOfficerData,
  agentData
}) => {
  const [showRigVedQuotes, setShowRigVedQuotes] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const isMobileOrTabletDevice = isMobileOrTablet();
  const [menuItems, setMenuItems] = useState([]);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [hoveredDropdown, setHoveredDropdown] = useState(null); // State to track hovered dropdown

  const rigVedQuotesRef = useRef(null); // Reference for RigVedQuotes
  const navbarRef = useRef(null); // Reference for the Navbar

  // Get user data 
  let userWebsite = loanOfficerData ? loanOfficerData.userWebsite : null;
  let loanofficerId = loanOfficerData ? loanOfficerData.ssid || '' : '';
  let emailAddress = loanOfficerData ? loanOfficerData.emailAddress || '' : '';
  let angelaiUrl = agentData ? agentData.staff.realtor.angelAiChatURL || '' : '';

  // Process user website URL
  if (userWebsite) {
    // Add 'https://' if not present at the beginning
    if (!userWebsite.startsWith('https://')) {
      userWebsite = `https://${userWebsite}`;
    }

    // Remove trailing '/' if present
    if (userWebsite.endsWith('/')) {
      userWebsite = userWebsite.slice(0, -1);
    }

    // Add specific suffixes based on the URL
    if (userWebsite.includes('angelai.com/partner/')) {
      userWebsite += '/#loan_service';
    } else if (userWebsite.includes('sunwestmortgage.com')) {
      userWebsite += '/#loan-products';
    }
  }

  // Handle hover events for desktop only
  const handleDropdownHover = (dropdownId, isHovering) => {
    if (!isMobileOrTabletDevice) {
      setHoveredDropdown(isHovering ? dropdownId : null);
    }
  };

  // Toggle dropdown on mobile click
  const handleDropdownClick = (dropdownId) => {
    if (isMobileOrTabletDevice) {
      setHoveredDropdown(hoveredDropdown === dropdownId ? null : dropdownId);
    }
  };

  const [links, setLinks] = useState([]);
  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_ANGELAI_JSON);
        setLinks(response.data.current);
      } catch (error) {
        console.error("Error fetching links:", error);
      }
    };
    fetchLinks();
  }, []);

  // Utility function to check if a URL belongs to the angelai.com domain
  const isAngelAiDomain = (url) => {
    try {
      const domain = new URL(url).hostname;
      return domain.includes("angelai.com");
    } catch (error) {
      return false;
    }
  };

  // New function to generate the brand logo URL based on the current URL
  const getBrandLogoUrl = () => {
    // Get the current URL
    const currentUrl = window.location.href;
    
    // Check if the URL matches the pattern www.example.com/agent/<agentName>
    const agentRegex = /\/agent\/([^\/]+)/;
    const match = currentUrl.match(agentRegex);
    
    if (match && match[1]) {
      // Extract the agent name from the URL
      const agentName = match[1];
      // Return the URL with the new pattern
      return `https://www.angelai.com/?key=agent-${agentName}`;
    }
    
    // Default URL if no match is found
    return 'https//www.angelai.com';
  };

  const renderSubMenu = (subItems) => {
    return subItems.map((subItem) => {
      if (subItem.submenu) {
        return (
          <NavDropdown
            title={subItem.id}
            key={subItem.id}
            id={`dropdown-${subItem.id}`}
            onMouseEnter={() => handleDropdownHover(subItem.id, true)} // Open on hover for desktop
            onMouseLeave={() => handleDropdownHover(subItem.id, false)} // Close on hover out for desktop
            onClick={() => handleDropdownClick(subItem.id)} // Toggle dropdown on mobile click
            show={
              isMobileOrTabletDevice
                ? hoveredDropdown === subItem.id
                : hoveredDropdown === subItem.id
            } // Control dropdown visibility on mobile
          >
            {renderSubMenu(subItem.submenu)}
          </NavDropdown>
        );
      } else {
        return (
          <NavDropdown.Item>

          </NavDropdown.Item>
        );
      }
    });
  };
  // Function to handle scrolling to sections
  async function scrollToSection1() {
    await handleShowOtherComponents();
    setTimeout(() => {
      const isDesktop = window.matchMedia("(min-width: 1024px)").matches;
      const navbarHeight = document.querySelector('nav').offsetHeight;
      const offset = 10;
      const sectionPosition = document.getElementById("applynow").offsetTop - (isDesktop ? (navbarHeight + offset) : 10);

      // Hide expanded navbar options
      document.getElementById("responsive-navbar-nav").classList.remove("show");

      // Scroll smoothly to the section
      window.scrollTo({
        top: sectionPosition,
        behavior: "smooth",
      });
    }, 300)

  }
  // Function to handle scrolling to sections
  async function scrollToSection() {
    await handleShowOtherComponents();
    setTimeout(() => {
      const isDesktop = window.matchMedia("(min-width: 1024px)").matches;
      const navbarHeight = document.querySelector('nav').offsetHeight;
      const offset = 10;
      const sectionPosition = document.getElementById("gettrueapproveScroll").offsetTop - (isDesktop ? (navbarHeight + offset) : 10);

      // Hide expanded navbar options
      document.getElementById("responsive-navbar-nav").classList.remove("show");

      // Scroll smoothly to the section
      window.scrollTo({
        top: sectionPosition,
        behavior: "smooth",
      });
    }, 300)

  }

  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const Navbar = document.getElementById("Navbar");
      const isDesktop = !isMobileOrTabletDevice;

      if (currentScrollPos === 0) {
        Navbar.classList.remove("navbarTransition");
        setNavbar(false);
      } else {
        if (prevScrollPos > currentScrollPos) {
          setNavbar(true);
        } else {
          if (isDesktop) {
            setNavbar(true);
          } else {
            setNavbar(false);
            setShowRigVedQuotes(false);
            Navbar.classList.add("navbarTransition");
          }
        }
      }

      setNavbarExpanded(false);

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobileOrTabletDevice]);

  const handleToggle = () => {
    // Check if the navbar is currently expanded
    if (navbarExpanded) {
      // If it is expanded, close it
      setNavbarExpanded(false);
    } else {
      // Otherwise, toggle it
      setNavbarExpanded(true);
    }
    // Close the RigVedQuotes whenever the navbar is toggled
    setShowRigVedQuotes(false);
  };

  // Function to handle RigVedQuote click
  const handleRigVedQuoteClick = () => {
    // Close the navbar and show RigVedQuotes if needed
    setNavbarExpanded(false);
    setShowRigVedQuotes(true);
  };

  const closeRigved = () => {
    setShowRigVedQuotes(false);
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside of both RigVedQuotes and navbar
    if (
      rigVedQuotesRef.current &&
      !rigVedQuotesRef.current.contains(event.target) &&
      navbarRef.current &&
      !navbarRef.current.contains(event.target)
    ) {
      setShowRigVedQuotes(false); // Only hide quotes
      setNavbarExpanded(false); // Collapse navbar
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Navbar
        id="Navbar"
        collapseOnSelect
        expand="lg"
        fixed="top"
        variant="dark"
        className={navbar ? "navbar active" : "navbar"}
        expanded={navbarExpanded}
        ref={navbarRef} // Attach ref to navbar
      >
        <Container className="navbar-container">
          <Navbar.Brand className="d-flex">
          <a href={getBrandLogoUrl()} target="_blank" rel="noopener noreferrer">
              <img
                src={links.brandLogo}
                alt={links.brandName}
                height="auto"
                width="auto"
                className="main_logo"
              />
            </a>
            <div
              className="brandTagLineContainer"
              onClick={handleRigVedQuoteClick}
              onMouseOver={() => {
                if (!isMobileOrTabletDevice) {
                  setShowRigVedQuotes(true);
                  setNavbarExpanded(false); // Close the navbar when hovering over RigVedQuote
                }
              }}
              onMouseOut={() => {
                if (!isMobileOrTabletDevice) {
                  setShowRigVedQuotes(false);
                }
              }}
              ref={rigVedQuotesRef} // Attach ref to RigVedQuotes
            >
              <p className="brandTagLine">
                {links.brandTagLine
                  ? HTMLReactParser(String(links.brandTagLine))
                  : links.brandTagLine}
              </p>
            </div>
            <div className="desktop">
              <UserWayAccessibilityMenu color="white" width={35} height={35} />
            </div>
          </Navbar.Brand>
          <Suspense fallback={<div>Loading...</div>}>
            <RigVedQuotes
              showRigVedQuotes={showRigVedQuotes}
              closeRigved={closeRigved}
            />
          </Suspense>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={handleToggle}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="nav-right">
              <Button
                variant="link"
                className="navbar-dark navbar-nav nav-link getlink truapproval-button"
                onClick={scrollToSection1}
                title="Apply Now"
                aria-label="Apply Now"
              >
                Apply Now
              </Button>
              <Button
                variant="link"
                className="navbar-dark navbar-nav nav-link getlink truapproval-button"
                onClick={scrollToSection}
                title="Get TRU Approved"
                aria-label="Get TRU Approved"
              >
                Get TRU Approved
              </Button>
              {loanOfficerActive ? (
                <>
                  <a
                    className="navbar-dark navbar-nav nav-link getlink"
                    href={
                      userWebsite
                        ? userWebsite
                        : "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    title={
                      loanOfficerData
                        ? "Loan Services offered by " +
                        capitalizeFirstLetter(loanOfficerData.name)
                        : "Loan Services offered"
                    }
                    aria-label="Loan Services offered"
                  >
                    Loan Services
                  </a>
                </>
              ) : (
                ""
              )}
              <Nav.Link
                href={agentData ? "https://www.swmc.com/angelai/knowyourrate?agentLead-" + capitalizeFirstLetter(agentData.staff.realtor.name) : "https://www.swmc.com/angelai/knowyourrate"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Today's Rate
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
        <Suspense fallback={<Fragment />}>

        </Suspense>
      </Navbar>
      <div className="mobile">
        <UserWayAccessibilityMenu color="white" width={35} height={35} />
      </div>
    </>
  );
};

export default HeaderNavbar;
