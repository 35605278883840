import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import "../Body/Body.css";
import {
  capitalizeFirstLetter,
  formatPhoneNumber,
  addInternationalCode,
} from "../Utils";
import parser from "html-react-parser";

const Body = React.memo(
  function Body({
  realtorData,
  loanOfficerActive,
  loanOfficerData,
  angelAiUserInfo,
}) {
  const imgCDNURL = process.env.REACT_APP_CDN + "realtor-cobranding/";
  const realtorName = realtorData ? realtorData.name : "";
  const subName = realtorData ? realtorData.subName : "";
  const angelAiName = angelAiUserInfo
    ? capitalizeFirstLetter(angelAiUserInfo.angelAIUserInfo.fullName)
    : "";
  const francmob = imgCDNURL + "francmob.webp";
  const franctel = imgCDNURL + "franctel.webp";
  const francemail = imgCDNURL + "francemail.webp";
  const francweb = imgCDNURL + "francweb.webp";
  const franclanguage = imgCDNURL + "franclanguage.webp";
  const franclocation = imgCDNURL + "franclocation.webp";
  const dotmatrix2 = imgCDNURL + "dotmatrix2.webp";
  const dotmatrix3 = imgCDNURL + "dotmatrixbanner.webp";

  let mobilePhoneNumberFormatted,
    mobilePhoneNumberWithInternationalCode,
    mobilePhoneNumberInTitle,
    workPhoneNumberFormatted,
    workPhoneNumberWithInternationalCode,
    workPhoneNumberInTitle;
  try {
    mobilePhoneNumberFormatted = realtorData
      ? formatPhoneNumber(realtorData.mobilePhoneNumber)
      : "";
    mobilePhoneNumberWithInternationalCode = realtorData
      ? addInternationalCode(realtorData.mobilePhoneNumber, "+1")
      : "";
    mobilePhoneNumberInTitle = "Contact me at " + mobilePhoneNumberFormatted;
  } catch (error) {
    console.error("Unable to get realtor mobile phone number.", error);
  }

  try {
    workPhoneNumberFormatted = realtorData
      ? formatPhoneNumber(realtorData.workPhoneNumber)
      : "";
    workPhoneNumberWithInternationalCode = realtorData
      ? addInternationalCode(realtorData.workPhoneNumber, "+1")
      : "";
    workPhoneNumberInTitle = "Contact me at " + workPhoneNumberFormatted;
  } catch (error) {
    console.error("Unable to get realtor work phone number.", error);
  }

  //Whether the realtor is also a loan originator
  let isRealtorAlsoALoanOriginator = false;
  if (realtorData) {
    isRealtorAlsoALoanOriginator =
      realtorData.isRealtorALoanOriginator !== undefined
        ? realtorData.isRealtorALoanOriginator
        : true;
  }
  //The main heading on the page
  // let realtorHeading = "Your Trusted Real Estate";
  // //Add "Loan Originator" to realtorHeading if the realtor is also a loan originator
  // //If isRealtorAlsoALoanOriginator is true, it appends "Broker and Loan Originator" to the realtorHeading else it appends "Agent"
  // realtorHeading = isRealtorAlsoALoanOriginator ? `${realtorHeading} Broker and Loan Originator` : `${realtorHeading} Agent`;
  // Check if additionalTextIndividual exists and is true in the JSON data
  let additionalText = "";
  if (realtorData && realtorData.bannerHeadingIndividual) {
    additionalText = isRealtorAlsoALoanOriginator
      ? realtorData.bannerHeadingForLoanOriginator // JSON value for loan originator
      : realtorData.bannerHeadingForAgent; // JSON value for agent
  } else {
    // Fallback to hardcoded values if the JSON parameter doesn't exist or is false
    additionalText = isRealtorAlsoALoanOriginator
      ? "For a Hassle-Free Homebuying Experience!<br/>Your Trusted Real Estate Broker and Loan Originator Have Teamed Up to Offer a One-Stop Shop!"
      : "Your Trusted Real Estate Agent<br/>For a Hassle-free Home Buying and Selling!";
  }
  function scrollToSection1() {
    const isDesktop = window.matchMedia("(min-width: 1024px)").matches; // Check if the screen width is greater than or equal to 1024px (adjust this value as needed)
    const navbarHeight = document.querySelector("nav").offsetHeight; // Get the height of the navigation bar
    const offset = 20; // Adjust this value to determine how much above the form the scroll will stop
    const sectionPosition =
      document.getElementById("contactusform").offsetTop -
      (isDesktop ? navbarHeight + offset : 0); // Adjust scroll position only for desktop
    window.scrollTo({
      top: sectionPosition,
      behavior: "smooth",
    });
  }

  const [isExpanded, setIsExpanded] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const bioRef = useRef(null);

  useEffect(() => {
    if (bioRef.current) {
      // console.log("Bio height:", bioRef.current.clientHeight);
      if (bioRef.current.clientHeight > 340) {
        setShowReadMore(true);
      }
    }
  }, []);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
    if (isExpanded) {
      // Scroll to top of bio when collapsing
      bioRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <div className="banner">
        <Container>
          <Row>
            <Col sm={12} md={12} lg={9}>
              {realtorName ? (
                <div className="realtorNameTop ps-2 mb-3">
                  <h1>{realtorName}</h1>

                  <p className="subname">{subName}</p>
                </div>
              ) : (
                <div className="realtorNameTop ps-2 realtorHeadingSpacing">
                  <h1 className="realtorHeadingText">
                    Be A Part of the Ai Revolution
                  </h1>
                </div>
              )}
            </Col>
            <Col sm={12} md={12} lg={7}>
              <div className="bannertextdesktop ps-2">
                {realtorName ? (
                  <>
                    <h2 className="realtor-heading">
                      {parser(additionalText)}
                    </h2>
                  </>
                ) : (
                  <>
                    <div className="realtorHeadingSpacing">
                      <h2 className="realtorHeadingText1">
                        The Rise of Ai: Use It or Lose It
                      </h2>
                      <h2 className="realtorHeadingText1">
                        No Tough Loans. Close Fast. Earn More.
                      </h2>
                    </div>
                  </>
                )}
              </div>
              {!realtorData ? (
                <div className="realtorHeading3 realtorHeadingSpacing">
                  <h2 className="realtorHeadingText1 mt-lg-5">
                    Embrace a Dynamic Workflow Transformation.
                  </h2>
                  <h2 className="realtorHeadingText1 mt-lg-4 bottom-pad ">
                    Unleash the Potential of Cutting-Edge Technology.
                  </h2>
                </div>
              ) : (
                ""
              )}
              <div>
                <Image
               
                 src="https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/mobilefamilyimage.webp"
                 srcSet="https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/mobilefamilyimage.webp 375w,
                         https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/mobilefamilyimage.webp 750w,
                         https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/mobilefamilyimage.webp 1125w"
                 className="mobilefamily img-fluid"
                 alt="mobilefamily"
                 loading="eager"
                 width="375"
                 height="481"
               />

              </div>
              <div>
                <Image
                  src={dotmatrix2}
                  className="dotmatrix2 img-fluid"
                  alt="dotmatrix2"
                  loading="lazy"
                />
              </div>
              <div>
                <Image
                  src={dotmatrix3}
                  className="dotmatrix3 img-fluid"
                  alt="dotmatrix3"
                 loading="lazy"

                />
              </div>
            </Col>
            {!realtorData ? (
              <Col sm={12} md={12} xl={5} lg={5} className='mt-lg-5'>
              <Image
                
                src={imgCDNURL + "banner-image.webp"}
                className="bannerimage img-fluid"
                alt="Banner Image"
                loading="eager"
                
              />
            </Col>
            
            ) : null}
          </Row>
          {realtorData ? (
            <Row className="joaquinrow">
              <Col sm={12} md={12} xl={3} lg={3}>
                <div className="joaquinsec">
                  <Image
                    src={realtorData.profileImage}
                    className="edvin img-fluid"
                    alt={realtorName} 
                  />
                  <p className="namejoaqu mt-2 mb-2">{realtorName}</p>
                  <p className="namejo2">
                    {realtorData.license ? (
                      <>
                        License# {realtorData.license}
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {realtorData.designation ? (
                      <>
                        {/* {realtorData.designation} */}
                        {parser(realtorData.designation)}
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {realtorData.mobilePhoneNumber ? (
                      <>
                        <Image src={francmob} alt="realtor mobile icon" />
                        <a
                          style={{ textDecoration: "none" }}
                          href={"tel:" + mobilePhoneNumberWithInternationalCode}
                          title={mobilePhoneNumberInTitle}
                          aria-label={mobilePhoneNumberInTitle}
                        >
                          {mobilePhoneNumberFormatted}
                        </a>
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {realtorData.workPhoneNumber ? (
                      <>
                        <Image src={franctel} alt="realtor telephone icon" />
                        <a
                          style={{ textDecoration: "none" }}
                          href={"tel:" + workPhoneNumberWithInternationalCode}
                          title={workPhoneNumberInTitle}
                          aria-label={workPhoneNumberInTitle}
                        >
                          {workPhoneNumberFormatted}
                        </a>
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {realtorData.emailAddress ? (
                      <>
                        <Image src={francemail} alt="realtor email icon" />
                        <a
                          href={"mailto:" + realtorData.emailAddress}
                          title={"Email me at " + realtorData.emailAddress}
                          aria-label={"Email me at " + realtorData.emailAddress}
                          className="text-decoration-none text-break"
                        >
                          {realtorData.emailAddress}
                        </a>
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {realtorData.userWebsite ? (
                      <>
                        <Image src={francweb} alt="realtor website icon" />
                        <a
                          href={realtorData.userWebsite}
                          target="_blank"
                          className="text-decoration-none text-break"
                          rel="noreferrer"
                        >
                          {realtorData.userWebsite}
                        </a>
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {realtorData.languages ? (
                      <>
                        <Image src={franclanguage} alt="realtor language icon" />
                        {realtorData.languages}
                        <br></br>
                      </>
                    ) : (
                      ""
                    )}
                    {realtorData.streetAddress &&
                    realtorData.cityStateZip &&
                    realtorData.streetAddress.trim() !== "" &&
                    realtorData.cityStateZip.trim() !== "" ? (
                      <>
                        <Image src={franclocation} alt="realtor location icon" />
                        {realtorData.streetAddress} {realtorData.cityStateZip}
                      </>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </Col>
              <Col sm={12} md={12} xl={4} lg={4}>
                <div className="closebuttnmobile">
                  <Button onClick={scrollToSection1} className="contactbutton">
                    Contact Us
                  </Button>
                </div>
                <div className="document-btn">
                  {realtorData.realtorCN1 ? (
                    <a
                      href={imgCDNURL + realtorData.realtorCN1}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={imgCDNURL + "CN15button.webp"}
                        className="CN15Btn"
                        alt="Button"
                        
                      />
                    </a>
                  ) : null}
                  {realtorData.realtorIABS ? (
                    <a
                      href={imgCDNURL + realtorData.realtorIABS}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={imgCDNURL + "IABSbutton.webp"}
                        className="IABSBtn"
                        alt="Button"
                      />
                    </a>
                  ) : null}
                </div>
                <div className="introtext">
                  <div>
                    {realtorData.realtorLogo ? (
                      <Image
                        src={realtorData.realtorLogo}
                        className="img-fluid realtorlogo"
                        alt="realtorLogo"
                       
                      />
                    ) : null}
                  </div>
                  <p
                    ref={bioRef}
                    style={{
                      maxHeight: isExpanded ? "none" : "350px",
                      overflow: "hidden",
                    }}
                  >
                    {realtorData.bio ? (
                      <>{parser(realtorData.bio)}</>
                    ) : (
                      <>
                        Hello, my name is {realtorName} and I am an experienced
                        Real Estate agent. Real Estate is my passion and I have
                        helped clients to purchase or sell their home. I take
                        pride in being a dedicated and experienced professional
                        committed to providing exceptional service to my
                        clients. With my philosophy of 100% customer
                        satisfaction, I make sure to thoroughly assess my
                        client's wants and needs to help them achieve their
                        affordability and goals.
                      </>
                    )}
                  </p>
                  {showReadMore && (
                    <button onClick={toggleExpand} className="read-more-btn">
                      {isExpanded ? "Read Less" : "Read More"}
                    </button>
                  )}
                  <div className="closebuttn btn-flex">
                    <Button
                      onClick={scrollToSection1}
                      className="contactbutton"
                    >
                      Contact Us
                    </Button>
                    {realtorData.realtorCN1 ? (
                      <a
                        href={imgCDNURL + realtorData.realtorCN1}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={imgCDNURL + "CN15button.webp"}
                          className="CN15Btn"
                          alt="Button"
                        />
                      </a>
                    ) : null}
                    {realtorData.realtorIABS ? (
                      <a
                        href={imgCDNURL + realtorData.realtorIABS}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={imgCDNURL + "IABSbutton.webp"}
                          className="IABSBtn"
                          alt="Button"
                          
                          
                        />
                      </a>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col sm={12} md={12} xl={5} lg={5}>
                <Image src={imgCDNURL + "banner-image.webp"} className="bannerimage" alt="" loading="eager" width="638" height="713" />
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Container>
      </div>

      <div className="mortgageofficer">
        <h3 className="mb-0 mortgageOfficerText">
          {realtorData ? (
            <>
              {loanOfficerActive ? (
                <>
                  As a Real Estate Agent working with{" "}
                  {capitalizeFirstLetter(loanOfficerData.name)}{" "}
                  {loanOfficerData.nmls ? (
                    <>(NMLS {loanOfficerData.nmls})</>
                  ) : (
                    ""
                  )}
                  , I can offer many benefits to my clients!
                </>
              ) : (
                <>
                  As a Real Estate Agent working, I can offer
                  many benefits to my clients!
                </>
              )}
            </>
          ) : (
            <>
              As a Real Estate Agent you will be working with {angelAiName},
              that can offer many benefits to your clients!
            </>
          )}
        </h3>
      </div>
    </>
  );
}
)

export default Body;