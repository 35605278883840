/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./TruApprovalAngelAi.css";

export default function AngelAi({ aiBrandingData, loanOfficerActive, loanOfficerData, realtorData }) {
  let AngelAiChatURL;
  if (loanOfficerActive) {
    AngelAiChatURL = loanOfficerData && loanOfficerData.angelAiChatURL ? loanOfficerData.angelAiChatURL : aiBrandingData.current.webAppURL;
  } else {
    AngelAiChatURL = realtorData && realtorData.angelAiChatURL ? realtorData.angelAiChatURL : aiBrandingData.current.webAppURL;
  }
  return (
    <>
      <div className="animatedmobilesection">
        <Container>
          <Row className="mbsect">
            <Col sm={12} md={12} xl={7} lg={7} className="mobtext">
              <h3 className="angelAihead">{aiBrandingData.current.brandName}</h3>
              <p className="Thoroughlytext1">
                With {aiBrandingData.current.brandName}, an Ai chat
                assistant, I can provide you with faster mortgage processing and
                accurate income analysis, making your homebuying quick and
                hassle-free. AngelAi is like having an underwriter and loan
                officer, 24/7, in my assistance, enabling me to assist you
                confidently with all your mortgage needs.
                <br /><br />
                <Button
                  variant="primary"
                  href={AngelAiChatURL}
                  target="_blank"
                  rel="noreferrer noopener"
                  title={
                    "Chat with " + aiBrandingData.current.brandName + " Now"
                  }
                  className="chatWithAngelAiBtn"
                >
                  Chat with {aiBrandingData.current.brandName} Now
                </Button>
              </p>
            </Col>

            <Col sm={12} md={12} xl={5} lg={5}>
              <video width="100%" autoPlay muted loop playsInline className="mmobilegif">
                <source src={process.env.REACT_APP_ANGELAI_IMAGES + "Morgan-to-AngelAi.mp4"} type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
