/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

import React, { useState, lazy, Suspense } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ProductSuiteUltraFastSaving.css";

const VideoModal = lazy(() => import("../Video/VideoModal"));

export default function UltraFastSaving() {
  const [showVideo, setShowVideo] = useState(false);
  const imgCDNURL = process.env.REACT_APP_CDN + "realtor-cobranding/";

  return (
    <>
      <div className="ultradiv">
        <img
          src={imgCDNURL + "dotmatrix.webp"}
          className="dotmatrixright1 img-fluild"
          alt="dotmatrix"
        ></img>
        <Container>
          <Row className="mobilereverse pt-5 pb-5">
            <Col sm={12} md={12} xl={6} lg={6} className='mt-xl-4 mt-xxl-5'>
              <div className="lapidessection2 mt-5 ">
                <h3 className="lapisectionheadbelow custom-headline">
                  Notable Sale
                </h3>

                <p className="ultratext custom-text">
                The Iconic Tirrana Residence – 
                A Masterpiece by Frank Lloyd Wright
                </p>
              </div>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-center position-relative pointer"
              sm={12}
              md={12}
              xl={6}
              lg={6}
              onClick={() => setShowVideo(true)}
            >
              <img
                src={imgCDNURL + "architectural-digest.webp"}
                className="ultraclosing img-fluild d-none d-md-block"
                alt="Inside One of Frank Lloyd Wright's Final-Ever Designs | Unique Spaces | Architectural Digest"
              ></img>
              <img
                src={imgCDNURL + "architectural-digest-mbl.webp"}
                className="ultraclosing img-fluild d-block d-md-none"
              alt="Inside One of Frank Lloyd Wright's Final-Ever Designs | Unique Spaces | Architectural Digest"
              ></img>
              <img
                src={`${imgCDNURL}play-icon.webp`}
                className="play-icon"
                alt="play"
              />
              <div className="vid-credit">
                Video Credit: Architectural Digest
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {showVideo && (
        <Suspense fallback={<React.Fragment />}>
          <VideoModal
            onClose={() => setShowVideo(false)}
            videoId="6L7NnZWeW-s"
          />
        </Suspense>
      )}
    </>
  );
}
