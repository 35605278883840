/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */

import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ProductSuiteUltraFastSaving.css";

export default function UltraFastSaving({ aiBrandingData }) {
  const imgCDNURL = process.env.REACT_APP_CDN + "realtor-cobranding/";

  return (
    <>
      <div className="ultradiv">
        <img
          src={imgCDNURL + "dotmatrix.webp"}
          className="dotmatrixright1 img-fluild"
          alt="dotmatrix"
        ></img>
        <Container>
          <Row className="mobilereverse pt-5 pb-5">
            <Col sm={12} md={12} xl={8} lg={8}>
              <div className="lapidessection2">
                <h3 className="lapisectionheadbelow">Ultra-Fast Closing</h3>

                <p className="ultratext">
                  Step into the future of mortgage applications with {aiBrandingData.current.brandName}: a
                  faster, easier, and transparent process. Get pre-qualified in
                  minutes, access personalized recommendations, and enjoy
                  stress-free expert guidance tailored to your unique financial
                  situation.
                </p>
              </div>
            </Col>
            <Col sm={12} md={12} xl={4} lg={4}>
              <img
                src={imgCDNURL + "ultraclosingpic.webp"}
                className="ultraclosing img-fluild"
                alt="ultraclosing"
              ></img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}