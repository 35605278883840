/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams } from "react-router-dom";
import parser from "html-react-parser";
import "./index.css";

export default function Disclaimer({ realtorDisclaimer, aiBrandingData }) {
  const agentName = useParams().agentName;
  const disclaimer = realtorDisclaimer || [];

  if (agentName === "albertsafdie" && disclaimer) {
    disclaimer[1] =
      "Celligence International, LLC is not affiliated with any third parties mentioned in the website. The names are owned by the respective third parties.";
  }

  return (
    <Container className="mb-3">
      <Row>
        <Col sm={12}>
          {disclaimer ? (
            <>
              {disclaimer?.map((realtorDisclaimerData, i) => (
                <p key={i} className="disclaimerText">
                  {parser(realtorDisclaimerData)}
                </p>
              ))}
            </>
          ) : (
            <>
              <p className="disclaimerText">
                Realtor® is a registered trademark of National Association of
                Realtors®.
              </p>
            </>
          )}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm={12} className="mt-3 mb-3">
          <p className="disclaimerText">
            <b>
              The website is hosted and created by{" "}
              {aiBrandingData.current.brandName}, a product of Celligence
              International, LLC. Celligence is not a finance or real estate
              services provider. Celligence builds brilliant artificial
              intelligence technology for many disparate industries.
            </b>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
